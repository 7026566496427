var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(4, 1fr)'],
      "gap": ['16px'],
      "margin-bottom": "16px"
    }
  }, _vm._l(_vm.steps, function (value, key) {
    return _c('c-flex', {
      key: key,
      attrs: {
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center",
        "gap": "8px",
        "cursor": "pointer",
        "border-radius": "8px",
        "_hover": {
          backgroundColor: 'neutral.superLightGray'
        }
      },
      on: {
        "click": function click($event) {
          return _vm.onChangeStep(key);
        }
      }
    }, [_c('c-text', {
      attrs: {
        "font-size": "14px",
        "font-weight": "500",
        "color": _vm.getColor(key, 'text')
      }
    }, [_vm._v(" " + _vm._s(value) + " ")]), _c('c-box', {
      attrs: {
        "width": "100%",
        "height": "8px",
        "border-radius": "8px",
        "background-color": _vm.getColor(key, 'background')
      }
    })], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }