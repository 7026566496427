<template>
  <c-box>
    <c-flex
      align-items="center"
      gap="16px"
    >
      <c-button
        variant="ghost"
        width="30px"
        min-width="30px"
        height="30px"
        margin="0"
        padding="0"
        @click="isOpenModalBackToPreview = true"
      >
        <c-box
          :width="['12px', '15px']"
          :height="['12px', '15px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-arrow-down.svg')"
            height="100%"
            width="100%"
            fill="#008C81"
            style="transform: rotate(90deg);"
          />
        </c-box>
      </c-button>
      <c-text
        color="neutral.superDarkGray"
        font-size="22px"
        font-weight="600"
      >
        Target & Saran
      </c-text>
    </c-flex>

    <c-flex
      margin-top="24px"
      justify-content="flex-end"
      gap="16px"
      margin-bottom="16px"
    >
      <c-button
        :variant="buttonSave.variant"
        border-radius="40px"
        variant-color="primary"
        :is-disabled="isSaveDisabled"
        :is-loading="isLoadingSave"
        @click="onSaveDraft"
      >
        {{ buttonSave.label }}
      </c-button>
    </c-flex>

    <NutritionalProfileSteps
      :draft="draft"
      :target-and-suggestions="$v?.$invalid ? -1 : 1"
      @change:step="(step) => handleChangeStep(step, true)"
    />
    <c-box>
      <FormInput
        v-model="form.primaryNutritionDiagnosis"
        type="textarea"
        label="Diagnosis Gizi Primer"
        placeholder="Masukkan diagnosis gizi primer"
        full-width
        is-required
        :is-invalid="isInvalidField($v.form.primaryNutritionDiagnosis)"
        invalid-text="Wajib diisi"
        @blur="$v.form.primaryNutritionDiagnosis.$touch"
      />

      <FormInput
        v-model="form.secondaryNutritionDiagnosis"
        type="textarea"
        label="Diagnosis Gizi Sekunder"
        placeholder="Masukkan diagnosis gizi sekunder"
        full-width
        is-required
        :is-invalid="isInvalidField($v.form.secondaryNutritionDiagnosis)"
        invalid-text="Wajib diisi"
        @blur="$v.form.secondaryNutritionDiagnosis.$touch"
      />

      <FormInput
        v-model="form.nutritionalIntervention"
        type="textarea"
        label="Intervensi Gizi"
        placeholder="Masukkan intervensi gizi"
        full-width
        is-required
        :is-invalid="isInvalidField($v.form.nutritionalIntervention)"
        invalid-text="Wajib diisi"
        @blur="$v.form.nutritionalIntervention.$touch"
      />

      <FormInput
        v-model="form.mealPlanGoals"
        type="textarea"
        label="Tujuan Meal Plan"
        placeholder="Masukkan tujuan mealplan"
        full-width
        is-required
        :is-invalid="isInvalidField($v.form.mealPlanGoals)"
        invalid-text="Wajib diisi"
        @blur="$v.form.mealPlanGoals.$touch"
      />

      <TestFieldArray v-slot="{ push, remove }">
        <c-flex
          v-for="(_, index) in form.thingsThatNeedToBeMaintained"
          :key="index"
          align-items="center"
          gap="8px"
        >
          <FormInput
            v-model="form.thingsThatNeedToBeMaintained[index]"
            type="textarea"
            :label="`Hal yang Perlu Dipertahankan ${index === 0 ? '' : index + 1}`.trim()"
            :placeholder="`Masukkan hal yang perlu dipertahankan ${index === 0 ? '' : index + 1}`.trim()"
            full-width
            is-required
            :is-invalid="isInvalidField($v.form.thingsThatNeedToBeMaintained.$each[index])"
            invalid-text="Wajib diisi"
            @blur="$v.form.thingsThatNeedToBeMaintained.$each[index].$touch"
          />
          <c-button
            v-if="index !== 0"
            background-color="transparent"
            height="50px"
            width="50px"
            @click="remove(form.thingsThatNeedToBeMaintained, index)"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-trash.svg')"
              height="38px"
              width="38px"
              fill="#D32737"
            />
          </c-button>
        </c-flex>
        <c-flex>
          <BaseButton
            margin="auto"
            size="small"
            border-radius="1000px"
            variant="outlined"
            width="80%"
            :right-svg-icon="require('@/assets/icons/icon-circle-plus.svg')"
            right-svg-icon-color="#008C81"
            @click="() => {
              push(form.thingsThatNeedToBeMaintained, '')
              $v.form.thingsThatNeedToBeMaintained.$touch()
            }"
          >
            Tambah
          </BaseButton>
        </c-flex>
      </TestFieldArray>

      <TestFieldArray
        v-slot="{ push, remove }"
        margin-top="16px"
      >
        <c-flex
          v-for="(_, index) in form.thingsThatNeedToBeImproved"
          :key="index"
          align-items="center"
          gap="8px"
        >
          <FormInput
            v-model="form.thingsThatNeedToBeImproved[index]"
            type="textarea"
            :label="`Hal yang Perlu diperbaiki ${index === 0 ? '' : index + 1}`.trim()"
            :placeholder="`Masukkan hal yang perlu diperbaiki ${index === 0 ? '' : index + 1}`.trim()"
            full-width
            is-required
            :is-invalid="isInvalidField($v.form.thingsThatNeedToBeImproved.$each[index])"
            invalid-text="Wajib diisi"
            @blur="$v.form.thingsThatNeedToBeImproved.$each[index].$touch"
          />
          <c-button
            v-if="index !== 0"
            background-color="transparent"
            height="50px"
            width="50px"
            @click="remove(form.thingsThatNeedToBeImproved, index)"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-trash.svg')"
              height="38px"
              width="38px"
              fill="#D32737"
            />
          </c-button>
        </c-flex>
        <c-flex>
          <BaseButton
            margin="auto"
            size="small"
            border-radius="1000px"
            variant="outlined"
            width="80%"
            :right-svg-icon="require('@/assets/icons/icon-circle-plus.svg')"
            right-svg-icon-color="#008C81"
            @click="() => {
              push(form.thingsThatNeedToBeImproved, '')
              $v.form.thingsThatNeedToBeImproved.$touch()
            }"
          >
            Tambah
          </BaseButton>
        </c-flex>
      </TestFieldArray>

      <TestFieldArray
        v-slot="{ push, remove }"
        margin-top="16px"
      >
        <c-flex
          v-for="(_, index) in form.targetForThisPeriod"
          :key="index"
          align-items="center"
          gap="8px"
        >
          <FormInput
            v-model="form.targetForThisPeriod[index]"
            type="textarea"
            :label="`Target Periode Ini ${index + 1}`.trim()"
            :placeholder="`Masukkan target periode ini ${index + 1}`.trim()"
            full-width
            is-required
            :is-invalid="isInvalidField($v.form.targetForThisPeriod.$each[index])"
            invalid-text="Wajib diisi"
            @blur="$v.form.targetForThisPeriod.$each[index].$touch"
          />
          
          <c-button
            v-if="![0, 1].includes(index)"
            background-color="transparent"
            height="50px"
            width="50px"
            @click="remove(form.targetForThisPeriod, index)"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-trash.svg')"
              height="38px"
              width="38px"
              fill="#D32737"
            />
          </c-button>
        </c-flex>
        <c-flex>
          <BaseButton
            margin="auto"
            size="small"
            border-radius="1000px"
            variant="outlined"
            width="80%"
            :right-svg-icon="require('@/assets/icons/icon-circle-plus.svg')"
            right-svg-icon-color="#008C81"
            @click="() => {
              push(form.targetForThisPeriod, '')
              $v.form.targetForThisPeriod.$touch()
            }"
          >
            Tambah
          </BaseButton>
        </c-flex>
      </TestFieldArray>

      <TestFieldArray
        v-slot="{ push, remove }"
        margin-top="16px"
      >
        <c-flex
          v-for="(_, index) in form.nutritionAndLifestyleAdvice"
          :key="index"
          align-items="center"
          gap="8px"
        >
          <FormInput
            v-model="form.nutritionAndLifestyleAdvice[index]"
            type="textarea"
            :label="`Saran Gizi dan Gaya Hidup ${index + 1}`.trim()"
            :placeholder="`Masukkan saran gizi dan gaya hidup ${index + 1}`.trim()"
            full-width
            is-required
            :is-invalid="isInvalidField($v.form.nutritionAndLifestyleAdvice.$each[index])"
            invalid-text="Wajib diisi"
            @blur="$v.form.nutritionAndLifestyleAdvice.$each[index].$touch"
          />
          <c-button
            v-if="![0, 1].includes(index)"
            background-color="transparent"
            height="50px"
            width="50px"
            @click="remove(form.nutritionAndLifestyleAdvice, index)"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-trash.svg')"
              height="38px"
              width="38px"
              fill="#D32737"
            />
          </c-button>
        </c-flex>
        <c-flex>
          <BaseButton
            margin="auto"
            size="small"
            border-radius="1000px"
            variant="outlined"
            width="80%"
            :right-svg-icon="require('@/assets/icons/icon-circle-plus.svg')"
            right-svg-icon-color="#008C81"
            @click="() => {
              push(form.nutritionAndLifestyleAdvice, '')
              $v.form.nutritionAndLifestyleAdvice.$touch()
            }"
          >
            Tambah
          </BaseButton>
        </c-flex>
      </TestFieldArray>

      <c-grid
        w="100%"
        :template-columns="['repeat(2, 1fr)']"
        :gap="['16px']"
        margin-top="24px"
      >
        <c-button
          variant="outline"
          variant-color="primary"
          height="60px"
          border-radius="60px"
          font-size="18px"
          :is-disabled="isLoadingSave"
          @click="onOpenModalCancel"
        >
          Sebelumnya
        </c-button>
        <c-button
          variant="solid"
          variant-color="primary"
          height="60px"
          border-radius="60px"
          font-size="18px"
          :is-disabled="isLoadingSave"
          :is-loading="isLoadingSave"
          @click="onSaveDraft"
        >
          Simpan
        </c-button>
      </c-grid>

      <BaseModal
        :is-open="!!isOpenModalCancel"
        :close-on-overlay-click="false"
        :with-button-close="false"
      >
        <template #header>
          <c-box
            display="flex"
            justify-content="center"
            margin="24px 0 0 0"
          >
            <c-image
              height="120px"
              object-fit="cover"
              :src="require('@/assets/images/image-question.svg')"
              alt="image question"
            />
          </c-box>
        </template>
        <template #body>
          <c-box
            justify-content="center"
            padding="30px 16px 20px 16px"
            text-align="center"
          >
            <BaseText
              size-mobile="16px"
              size-desktop="18px"
              color="primary.400"
              margin-bottom="8px"
            >
              Data kamu belum lengkap
            </BaseText>
            <BaseText
              size-mobile="12px"
              size-desktop="14px"
              color="neutral.darkGray"
            >
              Data kamu pada halaman ini belum lengkap. Apakah kamu yakin ingin melanjutkan pengisian data ke halaman sebelumnya?
            </BaseText>
          </c-box>
        </template>
        <template #footer>
          <c-flex
            gap="20px"
            width="100%"
            padding-left="24px"
            padding-right="24px"
            margin-bottom="24px"
          >
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              @click="onCloseModalCancel"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              @click="onConfirmModalCancel"
            >
              Ya
            </BaseButton>
          </c-flex>
        </template>
      </BaseModal>
    </c-box>

    <BaseModal
      :is-open="!!isOpenModalNext"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="image question"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          justify-content="center"
          padding="30px 16px 20px 16px"
          text-align="center"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="18px"
            color="primary.400"
            margin-bottom="8px"
          >
            Data kamu belum lengkap
          </BaseText>
          <BaseText
            size-mobile="12px"
            size-desktop="14px"
            color="neutral.darkGray"
          >
            Data kamu pada halaman ini belum lengkap. Apakah kamu yakin ingin melanjutkan pengisian data ke halaman selanjutnya?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            color="primary"
            rounded="1000px"
            width="100%"
            variant="outlined"
            @click="onCancelNext"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="onStepTo"
          >
            Ya
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>

    <!-- MODAL -->
    <ModalSuccessSaveData
      :is-open="isOpenModalSuccesSaveData"
      @close="isOpenModalSuccesSaveData = false"
    />

    <!-- Modal back to preview -->
    <BaseModal
      :is-open="isOpenModalBackToPreview"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="image question"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          justify-content="center"
          padding="30px 24px 20px 24px"
          text-align="center"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="18px"
            color="primary.400"
          >
            Apakah anda yakin<br>ingin membatalkan pengisian formulir?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            color="primary"
            rounded="1000px"
            width="100%"
            variant="outlined"
            @click="isOpenModalBackToPreview = false"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="handleBackToPreview"
          >
            Konfirmasi
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import _ from 'lodash'
import {
  proteinRequirementsperkgBodyWeightOptions,
  fatRequirementsOptions,
  fiberNeedsForAgeCategoryOptions, tabs,
} from '@/constants/meal-plans'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'
import NutritionalProfileSteps from '@/components/meal-plan/_widgets/steps.vue'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import FormInput from '@/views/client/kuisioner/forms/form-input-2.vue'
import { CBox, CButton, CFlex, CGrid, CImage, CText } from '@chakra-ui/vue'
import { minLength, required } from 'vuelidate/lib/validators'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import { parseErrors } from '@/utils/vuelidate-helpers/parse-errors'
import TestFieldArray from '@/views/profile/detail/test-field-array.vue'
import { isAllFormValid } from '@/components/meal-plan/_utils'
import ModalSuccessSaveData
  from '@/components/meal-plan/_widgets/modal-success-save-data.vue'
import EventBus from '@/utils/event-bus'

export default {
  name: 'FormTargetAndSuggestion',
  components: {
    ModalSuccessSaveData,
    TestFieldArray,
    CBox,
    CFlex,
    CImage,
    CButton,
    CText,
    CGrid,
    FormInput,
    BaseModal,
    BaseText,
    BaseButton,
    NutritionalProfileSteps,
  },
  props: {
    dataPreview: {
      type: Object,
      default: () => ({}),
    },
    draft: {
      type: [Object, null],
      default: null,
    },
    isDraftExist: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:validation'],
  data() {
    return {
      isOpenModalBackToPreview: false,
      isOpenModalNext: false,
      isOpenModalSuccesSaveData: false,
      formDuplicate: {},
      form: {
        primaryNutritionDiagnosis: null,
        secondaryNutritionDiagnosis: null,
        nutritionalIntervention: null,
        mealPlanGoals: null,
        thingsThatNeedToBeMaintained: [''],
        thingsThatNeedToBeImproved: [''],
        targetForThisPeriod: ['', ''],
        nutritionAndLifestyleAdvice: ['', ''],
      },
      isLoadingSave: false,
      isLoadingData: true,
      isOpenModalCancel: false,
      stepTo: {
        tab: '',
        savePosition: false,
      },
    }
  },
  computed: {
    isSaveDisabled() {
      if (!this.hasChanges) {
        return true
      }
      return this.isLoadingSave
    },
    buttonSave() {
      const isAllValid = isAllFormValid({
        nutritionalConditions: this.draft?.nutritionalConditions?.validation === 1,
        nutritionalIntake: this.draft?.nutritionalIntake?.validation === 1,
        bodyConditions: this.draft?.bodyConditions?.validation === 1,
        targetAndSuggestions: !this.$v?.$invalid,
      })
      return isAllValid ? {
        label: 'Simpan',
        variant: 'solid',
      } : {
        label: 'Simpan Draf',
        variant: 'outline',
      }
    },
    hasChanges() {
      return !_.isEqual(this.form, this.formDuplicate)
    },
    constants() {
      return {
        proteinRequirementsperkgBodyWeightOptions,
        fatRequirementsOptions,
        fiberNeedsForAgeCategoryOptions,
        tabs,
      }
    },
  },
  watch: {
    form: {
      handler() {
        const data = {
          form: 'target-and-suggestion',
          validation: this.$v.form.$invalid ? -1 : 1,
        }
        this.$emit('update:validation', data)
      },
      deep: true,
    },
  },
  mounted() {
    if (this.draft?.targetAndSuggestions && !_.isEmpty(this.draft?.targetAndSuggestions)) {
      this.form = this.draft?.targetAndSuggestions
      this.form.thingsThatNeedToBeMaintained = this.draft?.targetAndSuggestions?.thingsThatNeedToBeMaintained?.length > 0 ? this.draft?.targetAndSuggestions?.thingsThatNeedToBeMaintained : ['']
      this.form.thingsThatNeedToBeImproved = this.draft?.targetAndSuggestions?.thingsThatNeedToBeImproved?.length > 0 ? this.draft?.targetAndSuggestions?.thingsThatNeedToBeImproved : ['']
      this.form.targetForThisPeriod = this.draft?.targetAndSuggestions?.targetForThisPeriod?.length > 0 ? this.draft?.targetAndSuggestions?.targetForThisPeriod : ['', '']
      this.form.nutritionAndLifestyleAdvice = this.draft?.targetAndSuggestions?.nutritionAndLifestyleAdvice?.length > 0 ? this.draft?.targetAndSuggestions?.nutritionAndLifestyleAdvice : ['', '']

      this.$v.$touch()
    }
    setTimeout(() => {
      this.isLoadingData = false
    }, 1000)
    this.formDuplicate = _.cloneDeep(this.form)
  },
  methods: {
    isAllFormValid,
    isInvalidField,
    parseErrors,
    handleBackToPreview() {
      this.isOpenModalBackToPreview = false
      this.onSaveDraft()
      this.$router.replace({
        name: 'nutri.meal-plan.profile.detail',
        params: {
          ...this.$route.params,
          bypass: 1,
        },
      })
    },
    onOpenModalCancel() {
      if (!this.isLoadingSave) {
        this.isOpenModalCancel = true
      }
    },
    onCloseModalCancel() {
      this.isOpenModalCancel = false
    },
    onConfirmModalCancel() {
      this.onCloseModalCancel()
      this.onSaveDraft()
      this.$router.replace({
        name: 'nutri.meal-plan.profile.edit',
        params: this.$route.params,
        query: {
          tab: this.constants.tabs['body-condition'],
        },
      })
    },
    async onSaveDraft() {
      try {
        this.isLoadingSave = true

        let payload = {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: Number(this.$route.params.month),
          targetAndSuggestions: {
            ...this.form,
            validation: this.$v.$invalid ? -1 : 1,
          },
        }

        await reqNutriMealPlan.updateMealPlanDraft(this.$store.getters.axios, { payload })
        
        // this.$router.push({
        //   name: 'nutri.meal-plan.profile.edit',
        //   params: {
        //     clientId: this.$route.params.clientId,
        //     programId: this.$route.params.programId,
        //     month: this.$route.params.month,
        //   },
        //   query: {
        //     tab: this.constants.tabs['body-condition'],
        //   },
        // })

        this.$v.$touch()

        if (isAllFormValid({
          nutritionalConditions: this.draft?.nutritionalConditions?.validation === 1,
          nutritionalIntake: this.draft?.nutritionalIntake?.validation === 1,
          bodyConditions: this.draft?.bodyConditions?.validation === 1,
          targetAndSuggestions: !this.$v?.$invalid,
        })) {
          this.isOpenModalSuccesSaveData = true
        } else {
          this.$toast({
            title: 'Success',
            description: 'Successfully save to draft',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
          })
          EventBus.$emit('TRIGGER_SAVE_MEAL_PLAN', Date.now())
        }
        this.formDuplicate = _.cloneDeep(this.form)
      } catch (err) {
        this.$toast({
          title: 'Failed',
          description: err?.response?.data?.message || 'Something went wrong!',
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
        })
      } finally {
        this.isLoadingSave = false
      }
    },
    handleChangeStep(tab, savePosition) {
      this.$v.$touch()
      this.stepTo.tab = tab
      this.stepTo.savePosition = savePosition
      if (this.$v.$invalid) {
        this.isOpenModalNext = true
        return
      }
      this.onStepTo()
    },
    onCancelNext() {
      this.isOpenModalNext = false
    },
    async onStepTo() {
      try {
        await this.onSaveDraft()
        if (this.stepTo) {
          this.$router.replace({
            name: 'nutri.meal-plan.profile.edit',
            params: {
              ...this.$route.params,
              savePosition: this.stepTo.savePosition,
            },
            query: {
              tab: this.stepTo.tab,
            },
          })
        }
      } catch (e) {
        //
      }
    },
  },
  validations() {
    return {
      form: {
        primaryNutritionDiagnosis: {
          required,
        },
        secondaryNutritionDiagnosis: {
          required,
        },
        nutritionalIntervention: {
          required,
        },
        mealPlanGoals: {
          required,
        },
        thingsThatNeedToBeMaintained: {
          required,
          minLength: minLength(1),
          $each: {
            required,
          },
        },
        thingsThatNeedToBeImproved: {
          required,
          minLength: minLength(1),
          $each: {
            required,
          },
        },
        targetForThisPeriod: {
          required,
          minLength: minLength(2),
          $each: {
            required,
          },
        },
        nutritionAndLifestyleAdvice: {
          required,
          minLength: minLength(2),
          $each: {
            required,
          },
        },
      },
    }
  },
}
</script>
