<template>
  <c-grid
    w="100%"
    :template-columns="['repeat(4, 1fr)']"
    :gap="['16px']"
    margin-bottom="16px"
  >
    <c-flex
      v-for="(value, key) in steps"
      :key="key"
      flex-direction="column"
      justify-content="center"
      align-items="center"
      gap="8px"
      cursor="pointer"
      border-radius="8px"
      :_hover="{
        backgroundColor: 'neutral.superLightGray',
      }"
      @click="onChangeStep(key)"
    >
      <c-text
        font-size="14px"
        font-weight="500"
        :color="getColor(key, 'text')"
      >
        {{ value }}
      </c-text>
      <c-box
        width="100%"
        height="8px"
        border-radius="8px"
        :background-color="getColor(key, 'background')"
      />
    </c-flex>
  </c-grid>
</template>

<script>
import { tabs } from '@/constants/meal-plans'
export default {
  props: {
    draft: {
      type: Object,
      default: () => {},
    },
    nutritionalConditions: {
      type: [Number, null],
      default: null, // 1: valid, 0: blank, -1: invalid, null: using data response `validation` from draft
    },
    nutritionalIntake: {
      type: [Number, null],
      default: null, // 1: valid, 0: blank, -1: invalid, null: using data response `validation` from draft
    },
    bodyConditions: {
      type: [Number, null],
      default: null, // 1: valid, 0: blank, -1: invalid, null: using data response `validation` from draft
    },
    targetAndSuggestions: {
      type: [Number, null],
      default: null, // 1: valid, 0: blank, -1: invalid, null: using data response `validation` from draft
    },
  },
  emits: ['change:step'],
  computed: {
    constants() {
      return {
        tabs,
      }
    },
    steps() {
      return {
        nutritionalConditions: 'Kondisi Giziku',
        nutritionalIntake: 'Asupan Gizi',
        bodyConditions: 'Kondisi Tubuh',
        targetAndSuggestions: 'Target & Saran',
      }
    },
    normalizeSteps() {
      return {
        nutritionalConditions: this.constants.tabs['nutritional-condition'],
        nutritionalIntake: this.constants.tabs['nutritional-intake'],
        bodyConditions: this.constants.tabs['body-condition'],
        targetAndSuggestions: this.constants.tabs['target-and-suggestion'],
      }
    },
    activeStep() {
      return this.$route.query.tab || ''
    },
  },
  methods: {
    getColor(key, type) {
      if (this.$props[key] !== null) {
        if (this.$props[key] === 1) {
          return 'primary.400'
        }
        return 'danger.400'
      }
      if (this.draft == null || !this.draft[key]) {
        if (type === 'text') {
          return 'neutral.lightGray'
        }
        return 'neutral.superLightGray'
      }
      if (key === 'nutritionalConditions') {
        if (this.draft[key]?.validation === 1) {
          return 'primary.400'
        }
        return 'danger.400'
      }

      if (key === 'nutritionalIntake') {
        if (this.draft[key]?.validation === 1) {
          return 'primary.400'
        }
        return 'danger.400'
      }

      if (key === 'bodyConditions') {
        if (this.draft[key]?.validation === 1) {
          return 'primary.400'
        }
        return 'danger.400'
      }

      if (key === 'targetAndSuggestions') {
        if (this.draft[key].validation === 1) {
          return 'primary.400'
        }
        return 'danger.400'
      }

      return 'neutral.black' // delete this if no longer used
    },
    onChangeStep(step) {
      if (this.activeStep !== this.normalizeSteps[step]) {
        this.$emit('change:step', this.normalizeSteps[step])
      }
    },
  },
}
</script>
