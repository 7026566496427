var render = function () {
  var _vm$basicData, _vm$basicData2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "16px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "width": "30px",
      "min-width": "30px",
      "height": "30px",
      "margin": "0",
      "padding": "0"
    },
    on: {
      "click": function click($event) {
        _vm.isOpenModalBackToPreview = true;
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('c-text', {
    attrs: {
      "color": "neutral.superDarkGray",
      "font-size": "22px",
      "font-weight": "600"
    }
  }, [_vm._v(" Kondisi Giziku ")])], 1), _c('c-flex', {
    attrs: {
      "justify-content": "flex-end",
      "gap": "16px",
      "margin-bottom": "16px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": _vm.buttonSave.variant,
      "border-radius": "40px",
      "variant-color": "primary",
      "is-disabled": _vm.isSaveDisabled,
      "is-loading": _vm.isLoadingSave
    },
    on: {
      "click": _vm.onSaveDraft
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonSave.label) + " ")])], 1), _c('NutritionalProfileSteps', {
    attrs: {
      "draft": _vm.draft,
      "nutritional-conditions": !_vm.isDisabledSubmit ? 1 : -1
    },
    on: {
      "change:step": function changeStep(step) {
        return _vm.handleChangeStep(step, true);
      }
    }
  }), _c('c-box', [_c('FormAccordionList', {
    attrs: {
      "allow-toggle": false
    }
  }, [_c('FormAccordionListItem', {
    attrs: {
      "label": "Data Diri",
      "is-error": !_vm.isSectionDataDiriValid
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "text",
      "label": "Nama Lengkap",
      "placeholder": "Masukkan nama lengkap",
      "is-required": "",
      "is-disabled": "",
      "is-invalid": _vm.$v.form.name.$invalid
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('FormInput', {
    attrs: {
      "value": _vm.form.age,
      "type": "number",
      "label": "Umur (tahun)",
      "placeholder": "Masukkan umur",
      "is-required": "",
      "is-disabled": "",
      "right-element": "Tahun",
      "is-invalid": _vm.$v.form.age.$invalid
    }
  }), _c('FormSelect', {
    attrs: {
      "label": "Jenis Kelamin",
      "placeholder": "Pilih Jenis Kelamin",
      "is-required": "",
      "is-disabled": "",
      "options": [{
        value: 'male',
        label: 'Laki-laki'
      }, {
        value: 'female',
        label: 'Perempuan'
      }],
      "is-invalid": _vm.$v.form.gender.$invalid
    },
    model: {
      value: _vm.form.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "gender", $$v);
      },
      expression: "form.gender"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "text",
      "label": "Berat Badan (kg)",
      "placeholder": "Masukkan berat badan (kg)",
      "is-required": "",
      "is-disabled": !_vm.isEditWeight,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.weight),
      "invalid-text": _vm.parseErrors('Berat Badan (kg)', _vm.$v.form.weight, _vm.customMessageErrors),
      "icon-right-element": !_vm.isEditWeight ? require('@/assets/icons/icon-edit-2.svg') : null,
      "icon-right-element-color": "#008C81",
      "right-element": "kg",
      "is-helper": "",
      "helper-text-color": "#888",
      "helper-text-type": "info",
      "helper-text": "Berat badan dari formulir gizi: ".concat((_vm$basicData = _vm.basicData) === null || _vm$basicData === void 0 ? void 0 : _vm$basicData.weight, " kg")
    },
    on: {
      "right-element": _vm.onEditWeight,
      "blur": _vm.$v.form.weight.$touch
    },
    model: {
      value: _vm.form.weight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "weight", $$v);
      },
      expression: "form.weight"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "text",
      "label": "Tinggi Badan (cm)",
      "placeholder": "Masukkan tinggi badan (cm)",
      "is-required": "",
      "is-disabled": !_vm.isEditHeight,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.height),
      "invalid-text": _vm.parseErrors('Tinggi Badan (cm)', _vm.$v.form.height, _vm.customMessageErrors),
      "icon-right-element": !_vm.isEditHeight ? require('@/assets/icons/icon-edit-2.svg') : null,
      "icon-right-element-color": "#008C81",
      "right-element": "cm",
      "is-helper": "",
      "helper-text-color": "#888",
      "helper-text-type": "info",
      "helper-text": "Tinggi badan dari formulir gizi: ".concat((_vm$basicData2 = _vm.basicData) === null || _vm$basicData2 === void 0 ? void 0 : _vm$basicData2.height, " cm"),
      "is-without-margin-bottom": ""
    },
    on: {
      "right-element": _vm.onEditHeight,
      "blur": _vm.$v.form.height.$touch
    },
    model: {
      value: _vm.form.height,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "height", $$v);
      },
      expression: "form.height"
    }
  })], 1), _c('FormAccordionListItem', {
    attrs: {
      "label": "Jenis Perhitungan & Kebutuhan Energi",
      "is-error": !_vm.isSectionJenisPerhitunganValid
    }
  }, [_c('FormSelect', {
    attrs: {
      "label": "Jenis Perhitungan",
      "placeholder": "Pilih jenis perhitungan",
      "is-required": "",
      "options": _vm.constants.calculationTypes,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.calculationType),
      "invalid-text": _vm.parseErrors('Jenis Perhitungan', _vm.$v.form.calculationType, _vm.customMessageErrors),
      "is-without-margin-bottom": ""
    },
    on: {
      "blur": _vm.$v.form.calculationType.$touch
    },
    model: {
      value: _vm.form.calculationType,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "calculationType", $$v);
      },
      expression: "form.calculationType"
    }
  })], 1), _vm.form.calculationType === 'child' ? _c('FormAccordionListItem', {
    attrs: {
      "label": "Status Gizi Anak",
      "is-error": !_vm.isSectionStatusGiziAnakValid,
      "default-is-open": ""
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Koreksi Tinggi Badan atau Panjang Badan (cm)",
      "placeholder": "Masukkan Koreksi Tinggi Badan atau Panjang Badan (cm)",
      "is-required": "",
      "right-element": "cm",
      "is-helper": "",
      "helper-text-color": "#888",
      "helper-text-type": "info",
      "helper-text": "Isi sesuai data dasar jika tidak ada koreksi",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.correctedHeight),
      "invalid-text": _vm.parseErrors('Koreksi Tinggi Badan atau Panjang Badan (cm)', _vm.$v.form.correctedHeight, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.correctedHeight.$touch
    },
    model: {
      value: _vm.form.correctedHeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "correctedHeight", $$v);
      },
      expression: "form.correctedHeight"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Indeks Massa Tubuh (IMT) (kg/m2)",
      "placeholder": "Masukkan Indeks Massa Tubuh (IMT) (kg/m2)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kg/m2",
      "is-invalid": _vm.$v.form.imt.$invalid
    },
    model: {
      value: _vm.form.imt,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "imt", $$v);
      },
      expression: "form.imt"
    }
  }), _c('FormSelect', {
    attrs: {
      "label": "Rentang Usia Anak",
      "placeholder": "Pilih Rentang Usia Anak",
      "is-required": "",
      "options": _vm.constants.childAgeRanges,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.childAgeRange),
      "invalid-text": _vm.parseErrors('Rentang Usia Anak', _vm.$v.form.childAgeRange, _vm.customMessageErrors),
      "is-without-margin-bottom": ""
    },
    on: {
      "blur": _vm.$v.form.childAgeRange.$touch
    },
    model: {
      value: _vm.form.childAgeRange,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "childAgeRange", $$v);
      },
      expression: "form.childAgeRange"
    }
  })], 1) : _vm._e(), _vm.form.calculationType === 'child' && _vm.form.childAgeRange === '0 - 60 bulan' ? _c('FormAccordionListItem', {
    attrs: {
      "label": "Status Gizi Anak 0 - 60 Bulan",
      "is-error": !_vm.isSectionStatusGiziAnak060BulanValid,
      "default-is-open": ""
    }
  }, [_c('FormSelect', {
    key: "childAgeRangeMonthNutritionalStatusWeightPerAgeOptions",
    attrs: {
      "label": "Z-Score Berat Badan/Umur (BB/U)",
      "placeholder": "Pilih Z-Score Berat Badan/Umur (BB/U)",
      "is-required": "",
      "options": _vm.childAgeRangeMonthNutritionalStatusWeightPerAgeOptions,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.childAgeRangeMonthNutritionalStatusWeightPerAge),
      "invalid-text": _vm.parseErrors('Z-Score Berat Badan/Umur (BB/U)', _vm.$v.form.childAgeRangeMonthNutritionalStatusWeightPerAge, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.childAgeRangeMonthNutritionalStatusWeightPerAge.$touch
    },
    model: {
      value: _vm.form.childAgeRangeMonthNutritionalStatusWeightPerAge,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "childAgeRangeMonthNutritionalStatusWeightPerAge", $$v);
      },
      expression: "form.childAgeRangeMonthNutritionalStatusWeightPerAge"
    }
  }), _c('FormInput', {
    key: "childAgeRangeMonthNutritionalStatusWeightPerAge",
    attrs: {
      "type": "text",
      "label": "Status Gizi Berat Badan/Umur",
      "placeholder": "Masukkan Status Gizi Berat Badan/Umur",
      "is-required": "",
      "is-disabled": "",
      "is-invalid": _vm.$v.form.childAgeRangeMonthNutritionalStatusWeightPerAge.$invalid
    },
    model: {
      value: _vm.form.childAgeRangeMonthNutritionalStatusWeightPerAge,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "childAgeRangeMonthNutritionalStatusWeightPerAge", $$v);
      },
      expression: "form.childAgeRangeMonthNutritionalStatusWeightPerAge"
    }
  }), _c('FormSelect', {
    key: "childAgeRangeMonthNutritionalStatusHeightPerAgeOptions",
    attrs: {
      "label": "Z-Score Panjang Badan atau Tinggi Badan/Umur (PB/U atau TB/U)",
      "placeholder": "Pilih Z-Score Panjang Badan atau Tinggi Badan/Umur (PB/U atau TB/U)",
      "is-required": "",
      "options": _vm.childAgeRangeMonthNutritionalStatusHeightPerAgeOptions,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.childAgeRangeMonthNutritionalStatusHeightPerAge),
      "invalid-text": _vm.parseErrors('Z-Score Panjang Badan atau Tinggi Badan/Umur (PB/U atau TB/U)', _vm.$v.form.childAgeRangeMonthNutritionalStatusHeightPerAge, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.childAgeRangeMonthNutritionalStatusHeightPerAge.$touch
    },
    model: {
      value: _vm.form.childAgeRangeMonthNutritionalStatusHeightPerAge,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "childAgeRangeMonthNutritionalStatusHeightPerAge", $$v);
      },
      expression: "form.childAgeRangeMonthNutritionalStatusHeightPerAge"
    }
  }), _c('FormInput', {
    key: "childAgeRangeMonthNutritionalStatusHeightPerAge",
    attrs: {
      "type": "text",
      "label": "Status Gizi Panjang Badan/Umur (PB/U)",
      "placeholder": "Masukkan Status Gizi Panjang Badan/Umur (PB/U)",
      "is-required": "",
      "is-disabled": "",
      "is-invalid": _vm.$v.form.childAgeRangeMonthNutritionalStatusHeightPerAge.$invalid
    },
    model: {
      value: _vm.form.childAgeRangeMonthNutritionalStatusHeightPerAge,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "childAgeRangeMonthNutritionalStatusHeightPerAge", $$v);
      },
      expression: "form.childAgeRangeMonthNutritionalStatusHeightPerAge"
    }
  }), _c('FormSelect', {
    key: "childAgeRangeMonthNutritionalStatusHeightPerWeightOptions",
    attrs: {
      "label": "Z-Score Panjang Badan atau Tinggi Badan/Berat Badan (PB/BB atau TB/BB)",
      "placeholder": "Pilih Z-Score Panjang Badan atau Tinggi Badan/Berat Badan (PB/BB atau TB/BB)",
      "is-required": "",
      "options": _vm.childAgeRangeMonthNutritionalStatusHeightPerWeightOptions,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.childAgeRangeMonthNutritionalStatusHeightPerWeight),
      "invalid-text": _vm.parseErrors('Z-Score Panjang Badan atau Tinggi Badan/Berat Badan (PB/BB atau TB/BB)', _vm.$v.form.childAgeRangeMonthNutritionalStatusHeightPerWeight, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.childAgeRangeMonthNutritionalStatusHeightPerWeight.$touch
    },
    model: {
      value: _vm.form.childAgeRangeMonthNutritionalStatusHeightPerWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "childAgeRangeMonthNutritionalStatusHeightPerWeight", $$v);
      },
      expression: "form.childAgeRangeMonthNutritionalStatusHeightPerWeight"
    }
  }), _c('FormInput', {
    key: "childAgeRangeMonthNutritionalStatusHeightPerWeight",
    attrs: {
      "type": "text",
      "label": "Status Gizi Panjang Badan atau Tinggi Badan/Berat Badan (PB/BB atau TB/BB)",
      "placeholder": "Masukkan Status Gizi Panjang Badan atau Tinggi Badan/Berat Badan (PB/BB atau TB/BB)",
      "is-required": "",
      "is-disabled": "",
      "is-invalid": _vm.$v.form.childAgeRangeMonthNutritionalStatusHeightPerWeight.$invalid
    },
    model: {
      value: _vm.form.childAgeRangeMonthNutritionalStatusHeightPerWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "childAgeRangeMonthNutritionalStatusHeightPerWeight", $$v);
      },
      expression: "form.childAgeRangeMonthNutritionalStatusHeightPerWeight"
    }
  }), _c('FormSelect', {
    key: "childAgeRangeMonthNutritionalStatusImtPerAgeOptions",
    attrs: {
      "label": "Z-Score Indeks Massa Tubuh/Umur (IMT/U)",
      "placeholder": "Pilih Z-Score Indeks Massa Tubuh/Umur (IMT/U)",
      "is-required": "",
      "options": _vm.childAgeRangeMonthNutritionalStatusImtPerAgeOptions,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.childAgeRangeMonthNutritionalStatusImtPerAge),
      "invalid-text": _vm.parseErrors('Z-Score Indeks Massa Tubuh/Umur (IMT/U)', _vm.$v.form.childAgeRangeMonthNutritionalStatusImtPerAge, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.childAgeRangeMonthNutritionalStatusImtPerAge.$touch
    },
    model: {
      value: _vm.form.childAgeRangeMonthNutritionalStatusImtPerAge,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "childAgeRangeMonthNutritionalStatusImtPerAge", $$v);
      },
      expression: "form.childAgeRangeMonthNutritionalStatusImtPerAge"
    }
  }), _c('FormInput', {
    key: "childAgeRangeMonthNutritionalStatusImtPerAge",
    attrs: {
      "type": "text",
      "label": "Status Gizi Indeks Massa Tubuh/Umur (IMT/U)",
      "placeholder": "Masukkan Status Gizi Indeks Massa Tubuh/Umur (IMT/U)",
      "is-required": "",
      "is-disabled": "",
      "is-invalid": _vm.$v.form.childAgeRangeMonthNutritionalStatusImtPerAge.$invalid
    },
    model: {
      value: _vm.form.childAgeRangeMonthNutritionalStatusImtPerAge,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "childAgeRangeMonthNutritionalStatusImtPerAge", $$v);
      },
      expression: "form.childAgeRangeMonthNutritionalStatusImtPerAge"
    }
  }), _c('FormSelect', {
    key: "childAgeRangeMonthNutritionalStatusConclusionOptions",
    attrs: {
      "label": "Pilih Kesimpulan Status Gizi Anak 0 - 60 Bulan",
      "placeholder": "Pilih Kesimpulan Status Gizi Anak 0 - 60 Bulan",
      "is-required": "",
      "options": _vm.constants.conclusionOptions,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.childAgeRangeMonthNutritionalStatusConclusionChoice),
      "invalid-text": _vm.parseErrors('Pilih Kesimpulan Status Gizi Anak 0 - 60 Bulan', _vm.$v.form.childAgeRangeMonthNutritionalStatusConclusionChoice, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.childAgeRangeMonthNutritionalStatusConclusionChoice.$touch
    },
    model: {
      value: _vm.form.childAgeRangeMonthNutritionalStatusConclusionChoice,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "childAgeRangeMonthNutritionalStatusConclusionChoice", $$v);
      },
      expression: "form.childAgeRangeMonthNutritionalStatusConclusionChoice"
    }
  }), _c('FormInput', {
    key: "childAgeRangeMonthNutritionalStatusConclusion",
    attrs: {
      "value": _vm.form.childAgeRangeMonthNutritionalStatusConclusion,
      "type": "text",
      "label": "Kesimpulan Status Gizi Anak 0 - 60 Bulan",
      "placeholder": "Masukkan Kesimpulan Status Gizi Anak 0 - 60 Bulan",
      "is-required": "",
      "is-disabled": "",
      "is-invalid": _vm.$v.form.childAgeRangeMonthNutritionalStatusConclusion.$invalid,
      "is-without-margin-bottom": ""
    }
  })], 1) : _vm._e(), _vm.form.calculationType === 'child' && _vm.form.childAgeRange === '> 5 - 18 tahun' ? _c('FormAccordionListItem', {
    attrs: {
      "label": "Status Gizi Anak > 5 - 18 Tahun",
      "is-error": !_vm.isSectionStatusGiziAnak518TahunValid,
      "default-is-open": ""
    }
  }, [_c('FormSelect', {
    key: "childAgeRangeYearNutritionalStatusImtPerAgeOptions",
    attrs: {
      "label": "Z-Score Indeks Massa Tubuh/Umur (IMT/U)",
      "placeholder": "Pilih Z-Score Indeks Massa Tubuh/Umur (IMT/U)",
      "is-required": "",
      "options": _vm.childAgeRangeYearNutritionalStatusImtPerAgeOptions,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.childAgeRangeYearNutritionalStatusImtPerAge),
      "invalid-text": _vm.parseErrors('Z-Score Indeks Massa Tubuh/Umur (IMT/U)', _vm.$v.form.childAgeRangeYearNutritionalStatusImtPerAge, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.childAgeRangeYearNutritionalStatusImtPerAge.$touch
    },
    model: {
      value: _vm.form.childAgeRangeYearNutritionalStatusImtPerAge,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "childAgeRangeYearNutritionalStatusImtPerAge", $$v);
      },
      expression: "form.childAgeRangeYearNutritionalStatusImtPerAge"
    }
  }), _c('FormInput', {
    key: "childAgeRangeYearNutritionalStatusImtPerAge",
    attrs: {
      "type": "text",
      "label": "Status Gizi Indeks Massa Tubuh/Umur (IMT/U)",
      "placeholder": "Masukkan Status Gizi Indeks Massa Tubuh/Umur (IMT/U)",
      "is-required": "",
      "is-disabled": "",
      "is-invalid": _vm.$v.form.childAgeRangeYearNutritionalStatusImtPerAge.$invalid,
      "is-without-margin-bottom": ""
    },
    model: {
      value: _vm.form.childAgeRangeYearNutritionalStatusImtPerAge,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "childAgeRangeYearNutritionalStatusImtPerAge", $$v);
      },
      expression: "form.childAgeRangeYearNutritionalStatusImtPerAge"
    }
  })], 1) : _vm._e(), _vm.form.calculationType === 'child' ? _c('FormAccordionListItem', {
    attrs: {
      "label": "Kebutuhan Energi Anak",
      "is-error": !_vm.isSectionKebutuhanEnergiAnakValid,
      "default-is-open": ""
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Berat Badan Ideal Anak (kg)",
      "placeholder": "Masukkan Berat Badan Ideal Anak (kg)",
      "is-required": "",
      "right-element": "kg",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.childIdealWeight),
      "invalid-text": _vm.parseErrors('Berat Badan Ideal Anak (kg)', _vm.$v.form.childIdealWeight, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.childIdealWeight.$touch
    },
    model: {
      value: _vm.form.childIdealWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "childIdealWeight", $$v);
      },
      expression: "form.childIdealWeight"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Tinggi atau Panjang Badan Ideal Anak (cm)",
      "placeholder": "Masukkan Tinggi atau Panjang Badan Ideal Anak (cm)",
      "is-required": "",
      "right-element": "cm",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.childIdealHeight),
      "invalid-text": _vm.parseErrors('Tinggi atau Panjang Badan Ideal Anak (cm)', _vm.$v.form.childIdealHeight, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.childIdealHeight.$touch
    },
    model: {
      value: _vm.form.childIdealHeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "childIdealHeight", $$v);
      },
      expression: "form.childIdealHeight"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "text",
      "label": "Usia sesuai Tinggi Badan atau Panjang Badan",
      "placeholder": "Masukkan Usia sesuai Tinggi Badan atau Panjang Badan",
      "is-required": "",
      "is-helper": "",
      "helper-text-color": "#888",
      "helper-text-type": "info",
      "helper-text": "Masukkan usia sesuai dengan satuan bulan atau tahun. Contoh isian: 11 bulan atau 5 tahun",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.ageAccordingHeight),
      "invalid-text": _vm.parseErrors('Usia sesuai Tinggi Badan atau Panjang Badan', _vm.$v.form.ageAccordingHeight, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.ageAccordingHeight.$touch
    },
    model: {
      value: _vm.form.ageAccordingHeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "ageAccordingHeight", $$v);
      },
      expression: "form.ageAccordingHeight"
    }
  }), _c('FormSelect', {
    attrs: {
      "label": "Jenis Perhitungan Energi Sesuai Usia Tinggi",
      "placeholder": "Pilih Jenis Perhitungan Energi Sesuai Usia Tinggi",
      "is-required": "",
      "is-disabled": !_vm.form.childAgeRange,
      "options": _vm.form.childAgeRange === '0 - 60 bulan' ? _vm.constants.childFormulas : _vm.constants.childFormulas2,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.formula),
      "invalid-text": !_vm.form.childAgeRange ? 'Pilih Rentang Usia Anak dahulu' : _vm.parseErrors('Jenis Perhitungan Energi Sesuai Usia Tinggi', _vm.$v.form.formula, _vm.customMessageErrors),
      "is-without-margin-bottom": _vm.form.formula !== 'manualFormula'
    },
    on: {
      "blur": _vm.$v.form.formula.$touch
    },
    model: {
      value: _vm.form.formula,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "formula", $$v);
      },
      expression: "form.formula"
    }
  }), _vm.form.formula === 'manualFormula' ? _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Total Kebutuhan Energi (kkal)",
      "placeholder": "Masukkan Total Kebutuhan Energi (kkal)",
      "is-required": "",
      "right-element": "kkal",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.manualFormulaTotalEnergyNeeded),
      "invalid-text": _vm.parseErrors('Total Kebutuhan Energi (kkal)', _vm.$v.form.manualFormulaTotalEnergyNeeded, _vm.customMessageErrors),
      "is-without-margin-bottom": ""
    },
    on: {
      "blur": _vm.$v.form.manualFormulaTotalEnergyNeeded.$touch
    },
    model: {
      value: _vm.form.manualFormulaTotalEnergyNeeded,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "manualFormulaTotalEnergyNeeded", $$v);
      },
      expression: "form.manualFormulaTotalEnergyNeeded"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.form.calculationType === 'child' && _vm.form.formula === 'monthFormula' ? _c('FormAccordionListItem', {
    attrs: {
      "label": "Rumus 0 - 10 Tahun",
      "is-error": !_vm.isSectionRumus010TahunValid,
      "default-is-open": ""
    }
  }, [_c('FormSelect', {
    attrs: {
      "label": "Opsi Perhitungan Sesuai Usia Tinggi",
      "placeholder": "Pilih Opsi Perhitungan Sesuai Usia Tinggi",
      "is-required": "",
      "options": _vm.constants.monthFormulas,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.monthFormulaCalculationAccordingAgeAndHeight),
      "invalid-text": _vm.parseErrors('Opsi Perhitungan Sesuai Usia Tinggi', _vm.$v.form.monthFormulaCalculationAccordingAgeAndHeight, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.monthFormulaCalculationAccordingAgeAndHeight.$touch
    },
    model: {
      value: _vm.form.monthFormulaCalculationAccordingAgeAndHeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "monthFormulaCalculationAccordingAgeAndHeight", $$v);
      },
      expression: "form.monthFormulaCalculationAccordingAgeAndHeight"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Total Kebutuhan Energi (kkal)",
      "placeholder": "Masukkan Total Kebutuhan Energi (kkal)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kkal",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.monthFormulaTotalEnergyNeeded),
      "invalid-text": "Selesaikan isian semua data terlebih dahulu",
      "is-without-margin-bottom": ""
    },
    model: {
      value: _vm.form.monthFormulaTotalEnergyNeeded,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "monthFormulaTotalEnergyNeeded", $$v);
      },
      expression: "form.monthFormulaTotalEnergyNeeded"
    }
  })], 1) : _vm._e(), _vm.form.calculationType === 'child' && _vm.form.formula === 'yearMaleFormula' ? _c('FormAccordionListItem', {
    attrs: {
      "label": "Rumus Laki-laki 11 - 18 Tahun",
      "is-error": !_vm.isSectionRumusLakiLaki1118TahunValid,
      "default-is-open": ""
    }
  }, [_c('FormSelect', {
    attrs: {
      "label": "Opsi Perhitungan Sesuai Usia Tinggi",
      "placeholder": "Pilih Opsi Perhitungan Sesuai Usia Tinggi",
      "is-required": "",
      "options": _vm.constants.yearMaleFormulas,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.yearMaleFormulaCalculationAccordingAgeAndHeight),
      "invalid-text": _vm.parseErrors('Opsi Perhitungan Sesuai Usia Tinggi', _vm.$v.form.yearMaleFormulaCalculationAccordingAgeAndHeight, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.yearMaleFormulaCalculationAccordingAgeAndHeight.$touch
    },
    model: {
      value: _vm.form.yearMaleFormulaCalculationAccordingAgeAndHeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "yearMaleFormulaCalculationAccordingAgeAndHeight", $$v);
      },
      expression: "form.yearMaleFormulaCalculationAccordingAgeAndHeight"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Total Kebutuhan Energi (kkal)",
      "placeholder": "Masukkan Total Kebutuhan Energi (kkal)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kkal",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.yearFormulaTotalEnergyNeeded),
      "invalid-text": "Selesaikan isian semua data terlebih dahulu",
      "is-without-margin-bottom": ""
    },
    model: {
      value: _vm.form.yearFormulaTotalEnergyNeeded,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "yearFormulaTotalEnergyNeeded", $$v);
      },
      expression: "form.yearFormulaTotalEnergyNeeded"
    }
  })], 1) : _vm._e(), _vm.form.calculationType === 'child' && _vm.form.formula === 'yearFemaleFormula' ? _c('FormAccordionListItem', {
    attrs: {
      "label": "Rumus Perempuan 11 - 18 Tahun",
      "is-error": !_vm.isSectionRumusPerempuan1118TahunValid,
      "default-is-open": ""
    }
  }, [_c('FormSelect', {
    attrs: {
      "label": "Opsi Perhitungan Sesuai Usia Tinggi",
      "placeholder": "Pilih Opsi Perhitungan Sesuai Usia Tinggi",
      "is-required": "",
      "options": _vm.constants.yearFemaleFormulas,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.yearFemaleFormulaCalculationAccordingAgeAndHeight),
      "invalid-text": _vm.parseErrors('Opsi Perhitungan Sesuai Usia Tinggi', _vm.$v.form.yearFemaleFormulaCalculationAccordingAgeAndHeight, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.yearFemaleFormulaCalculationAccordingAgeAndHeight.$touch
    },
    model: {
      value: _vm.form.yearFemaleFormulaCalculationAccordingAgeAndHeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "yearFemaleFormulaCalculationAccordingAgeAndHeight", $$v);
      },
      expression: "form.yearFemaleFormulaCalculationAccordingAgeAndHeight"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Total Kebutuhan Energi (kkal)",
      "placeholder": "Masukkan Total Kebutuhan Energi (kkal)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kkal",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.yearFormulaTotalEnergyNeeded),
      "invalid-text": "Selesaikan isian semua data terlebih dahulu",
      "is-without-margin-bottom": ""
    },
    model: {
      value: _vm.form.yearFormulaTotalEnergyNeeded,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "yearFormulaTotalEnergyNeeded", $$v);
      },
      expression: "form.yearFormulaTotalEnergyNeeded"
    }
  })], 1) : _vm._e(), _vm.form.calculationType === 'adult' ? _c('FormAccordionListItem', {
    attrs: {
      "label": "Status Gizi Dewasa",
      "is-error": !_vm.isSectionStatusGiziDewasaValid,
      "default-is-open": ""
    }
  }, [_c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Indeks Massa Tubuh (IMT) (kg/m2)",
      "placeholder": "Masukkan Indeks Massa Tubuh (IMT) (kg/m2)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kg/m2",
      "is-invalid": _vm.$v.form.imt.$invalid
    },
    model: {
      value: _vm.form.imt,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "imt", $$v);
      },
      expression: "form.imt"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "text",
      "label": "Status Gizi",
      "placeholder": "Masukkan Status Gizi",
      "is-required": "",
      "is-disabled": "",
      "is-invalid": _vm.$v.form.nutritionalStatus.$invalid
    },
    model: {
      value: _vm.form.nutritionalStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "nutritionalStatus", $$v);
      },
      expression: "form.nutritionalStatus"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Minimum Berat Badan Ideal (kg)",
      "placeholder": "Masukkan Minimum Berat Badan Ideal (kg)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kg",
      "is-invalid": _vm.$v.form.minIdealWeight.$invalid
    },
    model: {
      value: _vm.form.minIdealWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "minIdealWeight", $$v);
      },
      expression: "form.minIdealWeight"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Maximum Berat Badan Ideal (kg)",
      "placeholder": "Masukkan Maximum Berat Badan Ideal (kg)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kg",
      "is-invalid": _vm.$v.form.maxIdealWeight.$invalid
    },
    model: {
      value: _vm.form.maxIdealWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "maxIdealWeight", $$v);
      },
      expression: "form.maxIdealWeight"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Nilai Tengah Berat Badan Ideal (kg)",
      "placeholder": "Masukkan Nilai Tengah Berat Badan Ideal (kg)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kg",
      "is-invalid": _vm.$v.form.medianIdealWeight.$invalid
    },
    model: {
      value: _vm.form.medianIdealWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "medianIdealWeight", $$v);
      },
      expression: "form.medianIdealWeight"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Berat Badan Ideal Brocca (kg)",
      "placeholder": "Masukkan Berat Badan Ideal Brocca (kg)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kg",
      "is-invalid": _vm.$v.form.broccaIdealWeight.$invalid
    },
    model: {
      value: _vm.form.broccaIdealWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "broccaIdealWeight", $$v);
      },
      expression: "form.broccaIdealWeight"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Berat Badan Ideal Adjusted (kg)",
      "placeholder": "Masukkan Berat Badan Ideal Adjusted (kg)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kg",
      "is-invalid": _vm.$v.form.adjustedIdealWeight.$invalid,
      "is-without-margin-bottom": ""
    },
    model: {
      value: _vm.form.adjustedIdealWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "adjustedIdealWeight", $$v);
      },
      expression: "form.adjustedIdealWeight"
    }
  })], 1) : _vm._e(), _vm.form.calculationType === 'adult' ? _c('FormAccordionListItem', {
    attrs: {
      "label": "Kebutuhan Energi Dewasa",
      "is-error": !_vm.isSectionKebutuhanEnergiDewasaValid,
      "default-is-open": ""
    }
  }, [_c('FormSelect', {
    attrs: {
      "label": "Rumus Kebutuhan Energi Dewasa",
      "placeholder": "Pilih Rumus Kebutuhan Energi Dewasa",
      "is-required": "",
      "options": _vm.constants.adultFormulas,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.formula),
      "invalid-text": _vm.parseErrors('Rumus Kebutuhan Energi Dewasa', _vm.$v.form.formula, _vm.customMessageErrors),
      "is-without-margin-bottom": _vm.form.formula !== 'manualFormula'
    },
    on: {
      "blur": _vm.$v.form.formula.$touch
    },
    model: {
      value: _vm.form.formula,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "formula", $$v);
      },
      expression: "form.formula"
    }
  }), _vm.form.formula === 'manualFormula' ? _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Total Kebutuhan Energi (kkal)",
      "placeholder": "Masukkan Total Kebutuhan Energi (kkal)",
      "is-required": "",
      "right-element": "kkal",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.manualFormulaTotalEnergyNeeded),
      "invalid-text": _vm.parseErrors('Total Kebutuhan Energi (kkal)', _vm.$v.form.manualFormulaTotalEnergyNeeded, _vm.customMessageErrors),
      "is-without-margin-bottom": ""
    },
    on: {
      "blur": _vm.$v.form.manualFormulaTotalEnergyNeeded.$touch
    },
    model: {
      value: _vm.form.manualFormulaTotalEnergyNeeded,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "manualFormulaTotalEnergyNeeded", $$v);
      },
      expression: "form.manualFormulaTotalEnergyNeeded"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.form.calculationType === 'adult' && _vm.form.formula === 'mifflinStJeorFormula' ? _c('FormAccordionListItem', {
    attrs: {
      "label": "Rumus Mifflin-St. Jeor",
      "is-error": !_vm.isSectionMifflinFormulaValid,
      "default-is-open": ""
    }
  }, [_c('FormSelect', {
    attrs: {
      "label": "Pilihan Berat Badan",
      "placeholder": "Pilih Berat Badan",
      "options": _vm.constants.weightOptions,
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.mifflinStJeorFormulaWeightChoice),
      "invalid-text": _vm.parseErrors('Pilihan Berat Badan', _vm.$v.form.mifflinStJeorFormulaWeightChoice, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.mifflinStJeorFormulaWeightChoice.$touch
    },
    model: {
      value: _vm.form.mifflinStJeorFormulaWeightChoice,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "mifflinStJeorFormulaWeightChoice", $$v);
      },
      expression: "form.mifflinStJeorFormulaWeightChoice"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Kebutuhan Energi Bassal (kkal)",
      "placeholder": "Masukkan Kebutuhan Energi Bassal (kkal)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kkal",
      "is-invalid": _vm.$v.form.mifflinStJeorFormulaBassEnergyRequirement.$dirty
    },
    model: {
      value: _vm.form.mifflinStJeorFormulaBassEnergyRequirement,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "mifflinStJeorFormulaBassEnergyRequirement", $$v);
      },
      expression: "form.mifflinStJeorFormulaBassEnergyRequirement"
    }
  }), _c('FormSelect', {
    attrs: {
      "label": "Kategori Aktivitas Fisik",
      "placeholder": "Pilih Kategori Aktivitas Fisik",
      "options": _vm.constants.physicalActivityOptions,
      "is-required": "",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.mifflinStJeorFormulaPhysicalActivity),
      "invalid-text": _vm.parseErrors('Kategori Aktivitas Fisik', _vm.$v.form.mifflinStJeorFormulaPhysicalActivity, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.mifflinStJeorFormulaPhysicalActivity.$touch
    },
    model: {
      value: _vm.form.mifflinStJeorFormulaPhysicalActivity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "mifflinStJeorFormulaPhysicalActivity", $$v);
      },
      expression: "form.mifflinStJeorFormulaPhysicalActivity"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "text",
      "label": "% Aktifitas Fisik",
      "placeholder": "Masukkan % Aktifitas Fisik",
      "is-required": "",
      "is-disabled": "",
      "right-element": "%",
      "is-invalid": _vm.$v.form.mifflinStJeorFormulaPhysicalActivity.$invalid
    },
    model: {
      value: _vm.form.mifflinStJeorFormulaPhysicalActivity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "mifflinStJeorFormulaPhysicalActivity", $$v);
      },
      expression: "form.mifflinStJeorFormulaPhysicalActivity"
    }
  }), _c('FormSelect', {
    attrs: {
      "label": "Kondisi Keibuan (kkal)",
      "placeholder": "Pilih Kondisi Keibuan (kkal)",
      "is-required": "",
      "options": _vm.constants.maternalConditionOptions,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.mifflinStJeorFormulaMaternalCondition),
      "invalid-text": _vm.parseErrors('Kondisi Keibuan (kkal)', _vm.$v.form.mifflinStJeorFormulaMaternalCondition, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.mifflinStJeorFormulaMaternalCondition.$touch
    },
    model: {
      value: _vm.form.mifflinStJeorFormulaMaternalCondition,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "mifflinStJeorFormulaMaternalCondition", $$v);
      },
      expression: "form.mifflinStJeorFormulaMaternalCondition"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Tambahan Kalori Kondisi Keibuan (kkal)",
      "placeholder": "Masukkan Tambahan Kalori Kondisi Keibuan (kkal)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kkal",
      "is-invalid": _vm.$v.form.mifflinStJeorFormulaMaternalCondition.$invalid
    },
    model: {
      value: _vm.form.mifflinStJeorFormulaMaternalCondition,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "mifflinStJeorFormulaMaternalCondition", $$v);
      },
      expression: "form.mifflinStJeorFormulaMaternalCondition"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Defisit Kalori yang Diberikan (kkal)",
      "placeholder": "Masukkan Defisit Kalori yang Diberikan (kkal)",
      "is-required": "",
      "right-element": "kkal",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.mifflinStJeorFormulaCalorieDeficit),
      "invalid-text": _vm.parseErrors('Defisit Kalori yang Diberikan (kkal)', _vm.$v.form.mifflinStJeorFormulaCalorieDeficit, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.mifflinStJeorFormulaCalorieDeficit.$touch
    },
    model: {
      value: _vm.form.mifflinStJeorFormulaCalorieDeficit,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "mifflinStJeorFormulaCalorieDeficit", $$v);
      },
      expression: "form.mifflinStJeorFormulaCalorieDeficit"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Total Kebutuhan Energi (kkal)",
      "placeholder": "Masukkan Total Kebutuhan Energi (kkal)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kkal",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.mifflinStJeorFormulaTotalEnergyNeeded),
      "invalid-text": "Selesaikan isian semua data terlebih dahulu",
      "is-without-margin-bottom": ""
    },
    model: {
      value: _vm.form.mifflinStJeorFormulaTotalEnergyNeeded,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "mifflinStJeorFormulaTotalEnergyNeeded", $$v);
      },
      expression: "form.mifflinStJeorFormulaTotalEnergyNeeded"
    }
  })], 1) : _vm._e(), _vm.form.calculationType === 'adult' && _vm.form.formula === 'perkeniFormula' ? _c('FormAccordionListItem', {
    attrs: {
      "label": "Rumus PERKENI",
      "is-error": !_vm.isSectionPerkeniFormulaValid,
      "default-is-open": ""
    }
  }, [_c('FormSelect', {
    attrs: {
      "label": "Pilihan Berat Badan",
      "placeholder": "Pilih Berat Badan",
      "is-required": "",
      "options": _vm.constants.weightOptions,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.perkeniFormulaWeightChoice),
      "invalid-text": _vm.parseErrors('Pilihan Berat Badan', _vm.$v.form.perkeniFormulaWeightChoice, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.perkeniFormulaWeightChoice.$touch
    },
    model: {
      value: _vm.form.perkeniFormulaWeightChoice,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "perkeniFormulaWeightChoice", $$v);
      },
      expression: "form.perkeniFormulaWeightChoice"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Kebutuhan Energi Bassal (kkal)",
      "placeholder": "Masukkan Kebutuhan Energi Bassal (kkal)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kkal",
      "is-invalid": _vm.$v.form.perkeniFormulaBassEnergyRequirement.$invalid
    },
    model: {
      value: _vm.form.perkeniFormulaBassEnergyRequirement,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "perkeniFormulaBassEnergyRequirement", $$v);
      },
      expression: "form.perkeniFormulaBassEnergyRequirement"
    }
  }), _c('FormSelect', {
    attrs: {
      "label": "Kategori Aktivitas Fisik",
      "placeholder": "Pilih Kategori Aktivitas Fisik",
      "is-required": "",
      "options": _vm.constants.physicalActivityOptions,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.perkeniFormulaPhysicalActivity),
      "invalid-text": _vm.parseErrors('Kategori Aktivitas Fisik', _vm.$v.form.perkeniFormulaPhysicalActivity, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.perkeniFormulaPhysicalActivity.$touch
    },
    model: {
      value: _vm.form.perkeniFormulaPhysicalActivity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "perkeniFormulaPhysicalActivity", $$v);
      },
      expression: "form.perkeniFormulaPhysicalActivity"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "% Aktifitas Fisik",
      "placeholder": "Masukkan % Aktifitas Fisik",
      "is-required": "",
      "is-disabled": "",
      "right-element": "%",
      "is-invalid": _vm.$v.form.perkeniFormulaPhysicalActivity.$invalid
    },
    model: {
      value: _vm.form.perkeniFormulaPhysicalActivity,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "perkeniFormulaPhysicalActivity", $$v);
      },
      expression: "form.perkeniFormulaPhysicalActivity"
    }
  }), _c('FormSelect', {
    attrs: {
      "label": "Kategori Faktor Umur",
      "placeholder": "Pilih Kategori Faktor Umur",
      "is-required": "",
      "options": _vm.constants.ageFactorOptions,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.perkeniFormulaAgeFactor),
      "invalid-text": _vm.parseErrors('Kategori Faktor Umur', _vm.$v.form.perkeniFormulaAgeFactor, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.perkeniFormulaAgeFactor.$touch
    },
    model: {
      value: _vm.form.perkeniFormulaAgeFactor,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "perkeniFormulaAgeFactor", $$v);
      },
      expression: "form.perkeniFormulaAgeFactor"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Faktor Umur (%)",
      "placeholder": "Masukkan Faktor Umur (%)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "%",
      "is-invalid": _vm.$v.form.perkeniFormulaAgeFactor.$invalid
    },
    model: {
      value: _vm.form.perkeniFormulaAgeFactor,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "perkeniFormulaAgeFactor", $$v);
      },
      expression: "form.perkeniFormulaAgeFactor"
    }
  }), _c('FormSelect', {
    attrs: {
      "label": "Faktor Stres Metabolik (%)",
      "placeholder": "Masukkan Faktor Stres Metabolik (%)",
      "is-required": "",
      "options": _vm.constants.stressFactorOptions,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.perkeniFormulaMetabolicStressFactor),
      "invalid-text": _vm.parseErrors('Faktor Stres Metabolik (%)', _vm.$v.form.perkeniFormulaMetabolicStressFactor, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.perkeniFormulaMetabolicStressFactor.$touch
    },
    model: {
      value: _vm.form.perkeniFormulaMetabolicStressFactor,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "perkeniFormulaMetabolicStressFactor", $$v);
      },
      expression: "form.perkeniFormulaMetabolicStressFactor"
    }
  }), _c('FormSelect', {
    attrs: {
      "label": "Faktor Status Gizi Kurang (%)",
      "placeholder": "Masukkan Faktor Status Gizi Kurang (%)",
      "is-required": "",
      "options": _vm.constants.statusFactorOptions,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.perkeniFormulaMalnutritionStatusFactor),
      "invalid-text": _vm.parseErrors('Faktor Status Gizi Kurang (%)', _vm.$v.form.perkeniFormulaMalnutritionStatusFactor, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.perkeniFormulaMalnutritionStatusFactor.$touch
    },
    model: {
      value: _vm.form.perkeniFormulaMalnutritionStatusFactor,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "perkeniFormulaMalnutritionStatusFactor", $$v);
      },
      expression: "form.perkeniFormulaMalnutritionStatusFactor"
    }
  }), _c('FormSelect', {
    attrs: {
      "label": "Faktor Status Gizi Lebih (%)",
      "placeholder": "Masukkan Faktor Status Gizi Lebih (%)",
      "is-required": "",
      "options": _vm.constants.statusFactorOptions,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.perkeniFormulaOvernutritionStatusFactor),
      "invalid-text": _vm.parseErrors('Faktor Status Gizi Lebih (%)', _vm.$v.form.perkeniFormulaOvernutritionStatusFactor, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.perkeniFormulaOvernutritionStatusFactor.$touch
    },
    model: {
      value: _vm.form.perkeniFormulaOvernutritionStatusFactor,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "perkeniFormulaOvernutritionStatusFactor", $$v);
      },
      expression: "form.perkeniFormulaOvernutritionStatusFactor"
    }
  }), _c('FormSelect', {
    attrs: {
      "is-required": "",
      "label": "Kondisi Keibuan (kkal)",
      "placeholder": "Pilih Kondisi Keibuan (kkal)",
      "options": _vm.constants.maternalConditionOptions,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.perkeniFormulaMaternalCondition),
      "invalid-text": _vm.parseErrors('Kondisi Keibuan (kkal)', _vm.$v.form.perkeniFormulaMaternalCondition, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.perkeniFormulaMaternalCondition.$touch
    },
    model: {
      value: _vm.form.perkeniFormulaMaternalCondition,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "perkeniFormulaMaternalCondition", $$v);
      },
      expression: "form.perkeniFormulaMaternalCondition"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Tambahan Kalori Kondisi Keibuan (kkal)",
      "placeholder": "Masukkan Tambahan Kalori Kondisi Keibuan (kkal)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kkal",
      "is-invalid": _vm.$v.form.perkeniFormulaMaternalCondition.$invalid
    },
    model: {
      value: _vm.form.perkeniFormulaMaternalCondition,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "perkeniFormulaMaternalCondition", $$v);
      },
      expression: "form.perkeniFormulaMaternalCondition"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Total Kebutuhan Energi (kkal)",
      "placeholder": "Masukkan Total Kebutuhan Energi (kkal)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kkal",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.perkeniFormulaTotalEnergyNeeded),
      "invalid-text": "Selesaikan isian semua data terlebih dahulu",
      "is-without-margin-bottom": ""
    },
    model: {
      value: _vm.form.perkeniFormulaTotalEnergyNeeded,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "perkeniFormulaTotalEnergyNeeded", $$v);
      },
      expression: "form.perkeniFormulaTotalEnergyNeeded"
    }
  })], 1) : _vm._e(), _vm.form.calculationType === 'adult' && _vm.form.formula === 'ckdFormula' ? _c('FormAccordionListItem', {
    attrs: {
      "label": "Rumus CKD",
      "is-error": !_vm.isSectionCkdFormulaValid,
      "default-is-open": ""
    }
  }, [_c('FormSelect', {
    attrs: {
      "is-required": "",
      "label": "Pilihan Berat Badan",
      "placeholder": "Pilih Berat Badan",
      "options": _vm.constants.weightOptions,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.ckdFormulaWeightChoice),
      "invalid-text": _vm.parseErrors('Pilihan Berat Badan', _vm.$v.form.ckdFormulaWeightChoice, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.ckdFormulaWeightChoice.$touch
    },
    model: {
      value: _vm.form.ckdFormulaWeightChoice,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "ckdFormulaWeightChoice", $$v);
      },
      expression: "form.ckdFormulaWeightChoice"
    }
  }), _c('FormSelect', {
    attrs: {
      "label": "Kebutuhan Energi per kg BB",
      "placeholder": "Pilih Kebutuhan Energi per kg BB",
      "is-required": "",
      "options": _vm.constants.ckdEnergyRequirementOptions,
      "is-invalid": _vm.isInvalidField(_vm.$v.form.ckdFormulaCkdEnergyRequirement),
      "invalid-text": _vm.parseErrors('Kebutuhan Energi per kg BB', _vm.$v.form.ckdFormulaCkdEnergyRequirement, _vm.customMessageErrors)
    },
    on: {
      "blur": _vm.$v.form.ckdFormulaCkdEnergyRequirement.$touch
    },
    model: {
      value: _vm.form.ckdFormulaCkdEnergyRequirement,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "ckdFormulaCkdEnergyRequirement", $$v);
      },
      expression: "form.ckdFormulaCkdEnergyRequirement"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Total Kebutuhan Energi (kkal)",
      "placeholder": "Masukkan Total Kebutuhan Energi (kkal)",
      "is-required": "",
      "is-disabled": "",
      "right-element": "kkal",
      "is-invalid": _vm.isInvalidField(_vm.$v.form.ckdFormulaTotalEnergyNeeded),
      "invalid-text": "Selesaikan isian semua data terlebih dahulu",
      "is-without-margin-bottom": ""
    },
    model: {
      value: _vm.form.ckdFormulaTotalEnergyNeeded,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "ckdFormulaTotalEnergyNeeded", $$v);
      },
      expression: "form.ckdFormulaTotalEnergyNeeded"
    }
  })], 1) : _vm._e()], 1), _c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(2, 1fr)'],
      "gap": ['16px']
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "height": "60px",
      "border-radius": "60px",
      "font-size": "18px",
      "is-disabled": _vm.isLoadingSave
    },
    on: {
      "click": _vm.onOpenModalCancel
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "height": "60px",
      "border-radius": "60px",
      "font-size": "18px",
      "is-disabled": _vm.isLoadingSave,
      "is-loading": _vm.isLoadingSave
    },
    on: {
      "click": function click($event) {
        return _vm.handleChangeStep(_vm.constants.tabs['nutritional-intake'], false);
      }
    }
  }, [_vm._v(" Selanjutnya ")])], 1), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalCancel,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "image question"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px 20px 24px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah anda yakin"), _c('br'), _vm._v("ingin membatalkan pengisian formulir? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": _vm.onCloseModalCancel
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.onConfirmModalCancel
          }
        }, [_vm._v(" Konfirmasi ")])], 1)];
      },
      proxy: true
    }])
  })], 1), _c('ModalSuccessSaveData', {
    attrs: {
      "is-open": _vm.isOpenModalSuccesSaveData
    },
    on: {
      "close": function close($event) {
        _vm.isOpenModalSuccesSaveData = false;
      }
    }
  }), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalNext,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "image question"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 16px 20px 16px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400",
            "margin-bottom": "8px"
          }
        }, [_vm._v(" Data kamu belum lengkap ")]), _c('BaseText', {
          attrs: {
            "size-mobile": "12px",
            "size-desktop": "14px",
            "color": "neutral.darkGray"
          }
        }, [_vm._v(" Data kamu pada halaman ini belum lengkap. Apakah kamu yakin ingin melanjutkan pengisian data ke halaman selanjutnya? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": _vm.onCancelNext
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.onStepTo
          }
        }, [_vm._v(" Ya ")])], 1)];
      },
      proxy: true
    }])
  }), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalBackToPreview,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "image question"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px 20px 24px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "18px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah anda yakin"), _c('br'), _vm._v("ingin membatalkan pengisian formulir? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalBackToPreview = false;
            }
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": _vm.handleBackToPreview
          }
        }, [_vm._v(" Konfirmasi ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }